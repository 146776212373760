<script>
    export let active = false;

    import LogoIcon from "../layout/LogoIcon.svelte";
</script>

<style>
    @keyframes moveBackground{
        0%{
            background-position-y: 0;
        }
        100%{
            background-position-y: 10000%;
        }
    }

    .outer{
        box-sizing: border-box;
        width: 50%;
        margin: 0 auto;
    }

    .cloud{
        background: url(/cloud.svg) top center no-repeat;
        background-size: contain;
        width: 100%;
        aspect-ratio: 106/76;
        position: relative;
    }

    svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -45%);
        width: 45%;
        aspect-ratio: 1/1;
    }

    .pipe{
        width: 10%;
        margin: 0 auto;
        background: url(/stripes.svg);
        background-size: contain;
        animation: moveBackground 300s linear infinite;
        padding-top: 0;
        margin-bottom: 20%;

        transition: padding-top 2.9s linear, margin-bottom 2.9s linear;
    }

    .active{
        padding-top: 20%;
        margin-bottom: 0;
    }

    @media screen and (max-width: 650px){
        .cloud{
            transform: translateX(-25%);
            z-index: 2;
        }

        .pipe{
            transform: rotate(-90deg);
            transform-origin: top center;
            position: absolute;
            top: 50%;
            width: 5%;
            left: 11%;
        }
    }
</style>

<div class="outer">
    <div class="cloud">
        <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <LogoIcon rotating={active} />
        </svg>
    </div>
    <div class="pipe" class:active={active}></div>
</div>
