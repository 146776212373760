<script>
    export let showStream = false;
</script>

<style>
    .steamPage{
        width: 100%;
        aspect-ratio: 400/243;
        overflow: hidden;
        background: #3C3F41 url(/steampage/background.webp) center no-repeat;
        background-size: cover;
        box-shadow: 0 0 3px rgba(0, 0, 0, .7);
        box-sizing: border-box;
        padding-top: 16.75%;
        position: relative;
    }

    .steamPage img {
        width: 62%;
        max-width: none;
    }

    .content{
        width: 61%;
        display: block;
        margin: 0 auto;
    }

    .stream{
        display: block;
        margin: 0 auto;
        width: 61%;
        margin-bottom: 5px;
    }

    .slider{
        overflow: hidden;
        height: 0;
        transition: height 1s;
    }

    .visible{
        height: 65%;
    }

    @media screen and (max-width: 650px){
        .steamPage{
            transform: scale(1.1);
        }

        .visible{
            height: 80%;
        }
    }
</style>

<div class="steamPage">
    <div class="slider" class:visible={showStream}>
        <video
                autoplay
                muted
                loop
                src="/steampage/rocket.webm"
                poster="/steampage/stream.webp"
                class="stream"
        ></video>
    </div>
    <img src="/steampage/content.webp" class="content" alt="RoboStreamer makes your Steam Pages more interesting with a broadcast." />
</div>
