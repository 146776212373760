<script>
    import Cloud from "./Cloud.svelte";
    import Steampage from "./Steampage.svelte";

    let doStream = false;
    let showStream = false;

    setTimeout(() => {
        doStream = true;

        setTimeout(() => {
            showStream = true;
        }, 3000);
    }, 2000);
</script>

<style>
    .wrapper{
        display: flex;
        justify-content: center;
    }

    .diorama{
        position: relative;
        width: 100%;
        max-width: 350px;
    }

    @media screen and (max-width: 650px){
        .diorama{
            display: flex;
            align-items: center;
            margin-bottom: 25px;
        }
    }
</style>

<div class="wrapper">
    <div class="diorama">
        <Cloud active={doStream} />
        <Steampage {showStream} />
    </div>
</div>
